export default {
  "accounts-read": "api/admin/jobs/accounts/",
  "accounts-create": "api/admin/jobs/accounts/",
  "accounts-delete": "api/admin/jobs/accounts/{}/",
  "accounts-update": "api/admin/jobs/accounts/{}/",
  "accounts-detail": "api/admin/jobs/accounts/{}/",
  "accounts-detail-revision-version": "api/admin/jobs/accounts/{}/revision/{}/",
  "accounts-autocomplete": "api/admin/jobs/accounts/autocomplete/",

  "buildingTypes-read": "api/admin/jobs/building-types/",
  "buildingTypes-create": "api/admin/jobs/building-types/",
  "buildingTypes-delete": "api/admin/jobs/building-types/{}/",
  "buildingTypes-update": "api/admin/jobs/building-types/{}/",
  "buildingTypes-detail": "api/admin/jobs/building-types/{}/",
  "buildingTypes-detail-revision-version": "api/admin/jobs/building-types/{}/revision/{}/",
  "buildingTypes-autocomplete": "api/admin/jobs/building-types/autocomplete/",

  "buildingTypesFile-delete": "api/admin/jobs/building-types/{}/file/",

  "jobs-read": "api/job/jobs/jobs/",
  "jobs-create": "api/job/jobs/jobs/",
  "jobs-delete": "api/job/jobs/jobs/{}/",
  "jobs-update": "api/job/jobs/jobs/{}/",
  "jobs-detail": "api/job/jobs/jobs/{}/",
  "jobs-autocomplete": "api/job/jobs/job/autocomplete/",

  "jobQueues-read": "api/admin/jobs/job-queues/",
  "jobQueues-create": "api/admin/jobs/job-queues/",
  "jobQueues-delete": "api/admin/jobs/job-queues/{}/",
  "jobQueues-update": "api/admin/jobs/job-queues/{}/",
  "jobQueues-detail": "api/admin/jobs/job-queues/{}/",
  "jobQueues-detail-revision-version": "api/admin/jobs/job-queues/{}/revision/{}/",
  "jobQueues-autocomplete": "api/admin/jobs/job-queues/autocomplete/",

  "holidays-read": "api/admin/jobs/holidays/",
  "holidays-create": "api/admin/jobs/holidays/",
  "holidays-delete": "api/admin/jobs/holidays/{}/",
  "holidays-update": "api/admin/jobs/holidays/{}/",
  "holidays-detail": "api/admin/jobs/holidays/{}/",
  "holidays-detail-revision-version": "api/admin/jobs/holidays/{}/revision/{}/",
  "holidays-autocomplete": "api/admin/jobs/holidays/autocomplete/",

  "extraCharges-read": "api/admin/jobs/extra-charges/",
  "extraCharges-create": "api/admin/jobs/extra-charges/",
  "extraCharges-delete": "api/admin/jobs/extra-charges/{}/",
  "extraCharges-update": "api/admin/jobs/extra-charges/{}/",
  "extraCharges-detail": "api/admin/jobs/extra-charges/{}/",
  "extraCharges-detail-revision-version": "api/admin/jobs/extra-charges/{}/revision/{}/",
  "extraCharges-autocomplete": "api/admin/jobs/extra-charges/autocomplete/",

  "extraChargeSizes-read": "api/admin/jobs/extra-charge-sizes/",
  "extraChargeSizes-create": "api/admin/jobs/extra-charge-sizes/",
  "extraChargeSizes-delete": "api/admin/jobs/extra-charge-sizes/{}/",
  "extraChargeSizes-update": "api/admin/jobs/extra-charge-sizes/{}/",
  "extraChargeSizes-detail": "api/admin/jobs/extra-charge-sizes/{}/",
  "extraChargeSizes-detail-revision-version": "api/admin/jobs/extra-charge-sizes/{}/revision/{}/",
  "extraChargeSizes-autocomplete": "api/admin/jobs/extra-charge-sizes/autocomplete/",

  "extraChargeMappings-read": "api/admin/jobs/extra-charge-mappings/",
  "extraChargeMappings-create": "api/admin/jobs/extra-charge-mappings/",
  "extraChargeMappings-delete": "api/admin/jobs/extra-charge-mappings/{}/",
  "extraChargeMappings-update": "api/admin/jobs/extra-charge-mappings/{}/",
  "extraChargeMappings-detail": "api/admin/jobs/extra-charge-mappings/{}/",
  "extraChargeMappings-autocomplete": "api/admin/jobs/extra-charge-mappings/autocomplete/",

  "extraChargeJobs-read": "api/admin/jobs/extra-charge-jobs/",
  "extraChargeJobs-create": "api/admin/jobs/extra-charge-jobs/",
  "extraChargeJobs-update": "api/admin/jobs/extra-charge-jobs/{}/",
  "extraChargeJobs-detail": "api/admin/jobs/extra-charge-jobs/{}/",
  "extraChargeJobs-delete": "api/admin/jobs/extra-charge-jobs/{}/",

  "buildings-read": "api/admin/jobs/buildings/",
  "buildings-create": "api/admin/jobs/buildings/",
  "buildings-delete": "api/admin/jobs/buildings/{}/",
  "buildings-update": "api/admin/jobs/buildings/{}/",
  "buildings-detail": "api/admin/jobs/buildings/{}/",
  "buildings-detail-revision-version": "api/admin/jobs/buildings/{}/revision/{}/",
  "buildings-autocomplete": "api/admin/jobs/buildings/autocomplete/",

  "takeOffTypes-read": "api/admin/jobs/take-off-types/",
  "takeOffTypes-create": "api/admin/jobs/take-off-types/",
  "takeOffTypes-delete": "api/admin/jobs/take-off-types/{}/",
  "takeOffTypes-update": "api/admin/jobs/take-off-types/{}/",
  "takeOffTypes-detail": "api/admin/jobs/take-off-types/{}/",
  "takeOffTypes-detail-revision-version": "api/admin/jobs/take-off-types/{}/revision/{}/",
  "takeOffTypes-autocomplete": "api/admin/jobs/take-off-types/autocomplete/",

  "rateTypes-read": "api/admin/jobs/rate-types/",
  "rateTypes-create": "api/admin/jobs/rate-types/",
  "rateTypes-delete": "api/admin/jobs/rate-types/{}/",
  "rateTypes-update": "api/admin/jobs/rate-types/{}/",
  "rateTypes-detail": "api/admin/jobs/rate-types/{}/",
  "rateTypes-detail-revision-version": "api/admin/jobs/rate-types/{}/revision/{}/",
  "rateTypes-autocomplete": "api/admin/jobs/rate-types/autocomplete/",

  "rateNames-read": "api/admin/jobs/rate-names/",
  "rateNames-create": "api/admin/jobs/rate-names/",
  "rateNames-delete": "api/admin/jobs/rate-names/{}/",
  "rateNames-update": "api/admin/jobs/rate-names/{}/",
  "rateNames-detail": "api/admin/jobs/rate-names/{}/",
  "rateNames-detail-revision-version": "api/admin/jobs/rate-names/{}/revision/{}/",
  "rateNames-autocomplete": "api/admin/jobs/rate-names/autocomplete/",

  "ranges-read": "api/admin/jobs/ranges/",
  "ranges-create": "api/admin/jobs/ranges/",
  "ranges-delete": "api/admin/jobs/ranges/{}/",
  "ranges-update": "api/admin/jobs/ranges/{}/",
  "ranges-detail": "api/admin/jobs/ranges/{}/",
  "ranges-detail-revision-version": "api/admin/jobs/ranges/{}/revision/{}/",
  "ranges-autocomplete": "api/admin/jobs/ranges/autocomplete/",

  "jobTypes-read": "api/admin/jobs/job-types/",
  "jobTypes-create": "api/admin/jobs/job-types/",
  "jobTypes-delete": "api/admin/jobs/job-types/{}/",
  "jobTypes-update": "api/admin/jobs/job-types/{}/",
  "jobTypes-detail": "api/admin/jobs/job-types/{}/",
  "jobTypes-detail-revision-version": "api/admin/jobs/job-types/{}/revision/{}/",
  "jobTypes-autocomplete": "api/admin/jobs/job-types/autocomplete/",

  "jobGrades-read": "api/admin/jobs/job-grades/",
  "jobGrades-create": "api/admin/jobs/job-grades/",
  "jobGrades-delete": "api/admin/jobs/job-grades/{}/",
  "jobGrades-update": "api/admin/jobs/job-grades/{}/",
  "jobGrades-detail": "api/admin/jobs/job-grades/{}/",
  "jobGrades-detail-revision-version": "api/admin/jobs/job-grades/{}/revision/{}/",
  "jobGrades-autocomplete": "api/admin/jobs/job-grades/autocomplete/",

  "merchants-read": { url: "api/admin/jobs/merchants/", version: 3 },
  "merchants-create": { url: "api/admin/jobs/merchants/", version: 3 },
  "merchants-create-bulk": { url: "api/admin/jobs/merchants/bulk/", version: 3 },
  "merchants-delete": { url: "api/admin/jobs/merchants/{}/", version: 3 },
  "merchants-update": { url: "api/admin/jobs/merchants/{}/", version: 3 },
  "merchants-detail": { url: "api/admin/jobs/merchants/{}/", version: 3 },
  "merchants-detail-revision-version": { url: "api/admin/jobs/merchants/{}/revision/{}/", version: 3 },
  "merchants-autocomplete": { url: "api/admin/jobs/merchants/autocomplete/", version: 3 },

  "merchantGroups-read": "api/admin/jobs/merchant-groups/",
  "merchantGroups-create": "api/admin/jobs/merchant-groups/",
  "merchantGroups-delete": "api/admin/jobs/merchant-groups/{}/",
  "merchantGroups-update": "api/admin/jobs/merchant-groups/{}/",
  "merchantGroups-detail": "api/admin/jobs/merchant-groups/{}/",
  "merchantGroups-detail-revision-version": "api/admin/jobs/merchant-groups/{}/revision/{}/",
  "merchantGroups-autocomplete": "api/admin/jobs/merchant-groups/autocomplete/",

  "operatingBranches-read": "api/admin/jobs/operating-branches/",
  "operatingBranches-create": "api/admin/jobs/operating-branches/",
  "operatingBranches-delete": "api/admin/jobs/operating-branches/{}/",
  "operatingBranches-update": "api/admin/jobs/operating-branches/{}/",
  "operatingBranches-detail": "api/admin/jobs/operating-branches/{}/",
  "operatingBranches-detail-revision-version": "api/admin/jobs/operating-branches/{}/revision/{}/",
  "operatingBranches-autocomplete": "api/admin/jobs/operating-branches/autocomplete/",

  "jobStatusHistories-read": "api/admin/jobs/job-status-histories/",
  "jobStatusHistories-detail": "api/admin/jobs/job-status-histories/{}/",

  "jobStatusNotSynceds-read": "api/admin/jobs/job-status-not-synceds/",
  "jobStatusNotSynceds-detail": "api/admin/jobs/job-status-not-synceds/{}/",
  "jobStatusNotSynceds-service": "services/descriptor/describe/?name=admin-jobs-api:job-status-not-synceds",

  "tagMerchants-read": "api/admin/jobs/tag-merchants/",
  "tagMerchants-create": "api/admin/jobs/tag-merchants/",
  "tagMerchants-update": "api/admin/jobs/tag-merchants/{}/",
  "tagMerchants-detail": "api/admin/jobs/tag-merchants/{}/",
  "tagMerchants-detail-revision-version": "api/admin/jobs/tag-merchants/{}/revision/{}/",
  "tagMerchants-delete": "api/admin/jobs/tag-merchants/{}/",

  "tags-read": "api/admin/jobs/tags/",
  "tags-create": "api/admin/jobs/tags/",
  "tags-update": "api/admin/jobs/tags/{}/",
  "tags-detail": "api/admin/jobs/tags/{}/",
  "tags-detail-revision-version": "api/admin/jobs/tags/{}/revision/{}/",
  "tags-delete": "api/admin/jobs/tags/{}/",
  "tags-autocomplete": "api/admin/jobs/tags/autocomplete/",

  "plans-create": "api/admin/jobs/plans/",
  "plans-create-bulk": "api/admin/jobs/plans/bulk/",
  "plans-detail": "api/admin/jobs/plans/{}",
  "plans-read": "api/admin/jobs/buildings/{}/",
  "plans-update": "api/admin/jobs/plans/{}/",
  "plans-delete": "api/admin/jobs/plans/{}/",
  "plans-selected-delete": "api/admin/jobs/plans/{}/file/",

  "jobComment-read": "api/app/jobs/job/{}/comments/",
  "jobSummary-read": "api/app/jobs/job/{}/summary/",

  //descriptor ref
  "api:admin:jobs:buildingtype:ref-buildings-list": "api/admin/jobs/building-types/{}/buildings/",
  "api:admin:jobs:extrachargesize:ref-mappings-list": "api/admin/jobs/extra-charge-sizes/{}/mappings/",
  "api:admin:jobs:extracharge:ref-mappings-list": "api/admin/jobs/extra-charges/{}/mappings/",
  "api:admin:jobs:extracharge:ref-merchants-list": "api/admin/jobs/extra-charges/{}/merchants/",
  "api:admin:jobs:building:ref-plans-list": "api/admin/jobs/buildings/{}/plans/",

  "api:admin:jobs:merchantgroup:ref-merchants-list": "api/admin/jobs/merchant-groups/{}/merchants/",
  "api:admin:jobs:merchantgroup:ref-mapping-modes-list": "api/admin/jobs/merchant-groups/{}/mapping_modes/",

  "api:admin:jobs:ratetype:ref-job-grades-list": "api/admin/jobs/rate-types/{}/job_grades",
  "api:admin:multisite:branch:ref-holidays-list": "api/admin/multisite/branches/{}/holidays/",
  "api:admin:multisite:branch:ref-job-queues-list": "api/admin/multisite/branches/{}/job_queues/",
  "api:admin:multisite:branch:ref-merchants-list": "api/admin/multisite/branches/{}/merchants/",
  "api:admin:multisite:branch:ref-servers-list": "api/admin/multisite/branches/{}/servers/",
}

import axios from "axios";
import { merge, get, some, noop } from "lodash";

import { Urls as urlConfig, Sites as appConfig } from "App/Config";
import { Store } from "App/Redux";
import logout from "./logout.v3";
import refreshToken from "./refreshToken.v3";

const axiosInstance = axios.create(),
  refreshTokenEvtName = "refreshToken_v3",
  refreshTokenEvt = new CustomEvent(refreshTokenEvtName),
  resolveRefreshTokenListener = (resolve) => {
    lastReslv = resolve;
    global.addEventListener(refreshTokenEvtName, resolve, { once: true });
  },
  ERROR_BY_STATUSES = {
    400: "Data sent is invalid", // title: Bad Request
    401: "Session expired", // title: Unauthorized
    403: "You do not have permission to perform this action.", // title: Forbidden
    404: "Request Data Not Found", // title: Not Found
    405: "Method Not Allowed", // title: Method Not Allowed
    408: "Request Timeout",
    500: "Internal Server Error, please try again later.", // title: Internal Server Error

    // "offline": "Connection has been refused, please check your internet connection",
    offline: "Fail connecting to server, please try again",
    default: "Failed to fetch, please contact your admin!",
  };
let isRefreshingToken = false,
  lastReslv = noop;

axiosInstance.defaults.headers = {
  "Cache-Control": "no-cache",
};

function request(options = {}) {
  let url,
    urlObj,
    urlVersion,
    currentArgs = arguments,
    resultObj = { success: false, result: null },
    defOpt = {
      method: "GET",
      urlKey: null,
      url: null,
      baseURL: null,
      default: null, // returned value on failed
      key: null, // used in bulk request
      args: [],
      params: undefined,
      data: undefined,
      accessToken: Store.getState().userReducer.token,
      autoRefreshExpiredToken: true,
      useCustomErrorMessage: false,
      returnResultObject: false,
      responseType: "json",
      headers: {
        "Accept-Language": window.navigator ? navigator.language : "en-US",
        "X-Client-Offset": new Date().getTimezoneOffset(),
      },
      extra: {},
      onSuccess: noop,
      onFailed: noop,
      onBoth: noop,
      onBefore: noop,
      onUploadProgress: noop,
      onDownloadProgress: noop,
    };

  options = merge(defOpt, options);

  if (!urlConfig[options.urlKey]) {
    if (!options.url) throw `UrlKey ${options.urlKey} is invalid`;
  } else {
    urlObj = urlConfig[options.urlKey];
    url = urlObj.url ? urlObj.url : urlObj;
  }

  if (options.method.toUpperCase() === "GET")
    if (!options.params) options.params = options.data;

  options.headers = {
    ...(options.accessToken
      ? { Authorization: `Bearer ${options.accessToken}` }
      : null),
    ...options.headers,
  };

  urlVersion =
    "urlVersion" in options
      ? options.urlVersion
      : urlObj && urlObj.version
      ? urlObj.version
      : 2;

  for (let i = 0; i < options.args.length; i++)
    url = url.replace("{}", options.args[i]);

  options.onBefore(options);

  return axiosInstance
    .request({
      method: options.method.toUpperCase(),
      url: options.url || url,
      baseURL:
        options.baseURL ||
        (!options.url ? appConfig.url.api[`v${urlVersion}`] : undefined),
      headers: options.headers,
      params: options.params,
      data: options.data,
      responseType: options.responseType,
      onDownloadProgress: options.onDownloadProgress,
      onUploadProgress: options.onUploadProgress,
    })
    .then((rawRes) => {
      options.onSuccess(rawRes.data, options.extra, rawRes);
      options.onBoth(true, rawRes.data, options.extra, rawRes);

      resultObj = {
        success: true,
        result: rawRes.data,
        extra: options.extra,
        response: rawRes,
        key: options.key || options.urlKey,
      };

      return !options.returnResultObject ? rawRes.data : resultObj;
    })
    .catch(async (err) => {
      const resErrObj = { status: null, detail: null };
      let isResolved = true;

      if (err.response) {
        resErrObj.status = err.response.status;
        resErrObj.detail =
          ERROR_BY_STATUSES[err.response.status] ||
          ERROR_BY_STATUSES["default"];

        if (err.response.status >= 300 && err.response.status < 500) {
          if (err.response.status === 401 && options.autoRefreshExpiredToken) {
            isResolved = false;

            if (!isRefreshingToken) {
              isRefreshingToken = true;

              const isTokenRefreshed = await refreshToken();

              isRefreshingToken = false;

              if (isTokenRefreshed) {
                global.dispatchEvent(refreshTokenEvt);
                return await request({ ...currentArgs[0] });
              } else {
                global.removeEventListener(refreshTokenEvtName, lastReslv);
                await logout();
              }
            } else {
              await new Promise(resolveRefreshTokenListener);
              return await request({ ...currentArgs[0] });
            }
          } else {
            if (!options.useCustomErrorMessage)
              resErrObj.detail =
                get(err.response, ["data", "detail"]) || err.response.data;
          }
        } else if (err.response.status < 300) {
          // technically connection issues
          resErrObj.detail = ERROR_BY_STATUSES["offline"];
        }
      } else {
        // fe error
        resErrObj.status = 500;
        resErrObj.detail = !options.useCustomErrorMessage
          ? err.message
          : ERROR_BY_STATUSES[500];
      }

      if (isResolved) {
        options.onFailed(resErrObj, options.extra, err.response);
        options.onBoth(false, resErrObj, options.extra, err.response);

        resultObj = {
          success: false,
          result: resErrObj,
          extra: options.extra,
          response: err.response,
          key: options.key || options.urlKey,
          default: options.default,
        };

        return !options.returnResultObject ? options.default : resultObj;
      }
    });
}

function fnRequest(r) {
  return request({ ...r, returnResultObject: true });
}

function bulk(requestsOption = [], options = {}) {
  options = merge(
    {
      returnResultObject: false,
      resultType: "object",
      onSuccess: noop,
      onFailed: noop,
      onBoth: noop,
    },
    options
  );

  return Promise.all(requestsOption.map(fnRequest)).then((results) => {
    const success = !some(results, { success: false }),
      finalResultsObj = options.resultType === "object" ? {} : [];

    for (const result of results) {
      const res = options.returnResultObject
        ? result
        : result.success
        ? result.result
        : result.default;

      if (options.resultType === "object") finalResultsObj[result.key] = res;
      else finalResultsObj.push(res);
    }

    if (success) options.onSuccess(finalResultsObj);
    else options.onFailed(finalResultsObj);

    options.onBoth(success, finalResultsObj);

    return finalResultsObj;
  });
}

request.bulk = bulk;

export default request;
